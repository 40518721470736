import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import ApiService from "@/core/services/ApiService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: () => {
      if(window.localStorage.getItem("signed_in")) {
        return { path: "/dashboard2" };
      } else {
        return { path: "/sign-in" };
      }
    },
    //redirect:  { path: "/dashboard2" },
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        redirect:  { path: "/dashboard2" },
        component: () => import("@/views/Dashboard.vue")
      },
      {
        path: "/dashboard2",
        name: "dashboard2",
        component: () => import("@/views/Dashboard2.vue")
      },
      /*{
        path: "/document-db",
        name: "document-db",
        component: () => import("@/views/documents/Overview.vue"),
        children: [
          {
            path: "checklists",
            name: "ddb-checklists",
            component: () => import("@/views/documents/checklists/List.vue")
          }
          {,
            path: "procedures",
            name: "ddb-procedures",
            component: () => import("@/views/documents/procedures/List.vue")
          },,
          {
            path: "risk-assesment",
            name: "ddb-risk-assesment",
            component: () => import("@/views/documents/riskassesment/List.vue")
          },
          {
            path: "guidelines",
            name: "ddb-guidelines",
            component: () => import("@/views/documents/guidelines/List.vue")
          }
      },*/
      {
        path: "/work-orders",
        name: "work-orders-list",
        component: () => import("@/views/workorders/List.vue")
      },
      {
        path: "/work-orders/create",
        name: "create-work-order",
        component: () => import("@/views/workorders/Create.vue")
      },
      {
        path: "/maintenance/calendar",
        name: "maintenance-calendar",
        component: () => import("@/views/maintenance/Calendar.vue")
      },
      {
        path: "/work-orders/calendar",
        name: "work-orders-calendar",
        component: () => import("@/views/workorders/Calendar.vue")
      },
      {
        path: "/work-orders/scheduler",
        name: "work-orders-scheduler",
        component: () => import("@/views/workorders/GanttChart.vue")
      },
      {
        path: "/work-orders/view/:workOrderID",
        name: "work-order-details",
        component: () => import("@/views/workorders/Details.vue"),
        props: true
      },
      {
        path: "/projects-list",
        name: "projects-list",
        component: () => import("@/views/projects/List.vue")
      },
      {
        path: "/assets-categories",
        name: "assets-categories",
        component: () => import("@/views/assets/Categories.vue")
      },
      {
        path: "/assets-list",
        name: "assets-list",
        component: () => import("@/views/assets/List.vue")
      },
      {
        path: "/assets-schedule",
        name: "assets-schedule",
        component: () => import("@/views/assets/GanttChart.vue")
      },
      {
        path: "/document-db/checklists",
        name: "ddb-checklists",
        component: () => import("@/views/documents/checklists/List.vue")
      },
      {
        path: "/document-db/checklists/view/:listId",
        name: "ddb-checklist-view",
        component: () => import("@/views/documents/checklists/View.vue"),
        props: true
      },
      {
        path: "/warehouse/parts-list",
        name: "supply-chain-warehouse-parts",
        component: () => import("@/views/supplychain/warehouse/parts/List.vue")
      },
      {
        path: "/warehouse/iaap",
        name: "supply-chain-warehouse-iaap",
        component: () => import("@/views/supplychain/warehouse/Dashboard.vue")
      },
      {
        path: "/maintenance/budgets",
        name: "maintenance-budgets-dashboard",
        component: () => import("@/views/maintenance/Dashboard.vue")
      },
      {
        path: "/hcm/workforce-management",
        name: "workforce-management",
        component: () => import("@/views/hcm/GanttChart.vue")
      },
      {
        path: "/assets-list/asset/:assetId",
        name: "asset-overview",
        props: true,
        component: () => import("@/views/assets/Overview.vue"),
        children: [
          {
            path: "general",
            name: "asset-overview-general",
            component: () => import("@/views/assets/details/General.vue")
          },
          {
            path: "type-specific",
            name: "asset-overview-type-specific",
            component: () => import("@/views/assets/details/TypeSpecific.vue")
          },
          {
            path: "manuals",
            name: "asset-overview-manuals",
            component: () => import("@/views/assets/details/Manuals.vue")
          },
          {
            path: "maintenance-records",
            name: "asset-overview-maintenance-records",
            component: () => import("@/views/assets/details/MaintenanceRecords.vue")
          },
          {
            path: "photos",
            name: "asset-overview-photos",
            component: () => import("@/views/assets/details/Photos.vue")
          },
          {
            path: "activity",
            name: "asset-overview-activity",
            component: () => import("@/views/assets/details/Activity.vue")
          },
          {
            path: "parts",
            name: "asset-overview-parts",
            component: () => import("@/views/assets/details/Parts.vue")
          }
        ]
      },
      {
        path: "/builder",
        name: "builder",
        component: () => import("@/views/Builder.vue")
      },
      {
        path: "/crafted/pages/profile",
        name: "profile",
        component: () => import("@/views/pages/Profile.vue"),
        children: [
          {
            path: "overview",
            name: "profile-overview",
            component: () => import("@/views/pages/profile/Overview.vue")
          },
          {
            path: "projects",
            name: "profile-projects",
            component: () => import("@/views/pages/profile/Projects.vue")
          },
          {
            path: "campaigns",
            name: "profile-campaigns",
            component: () => import("@/views/pages/profile/Campaigns.vue")
          },
          {
            path: "documents",
            name: "profile-documents",
            component: () => import("@/views/pages/profile/Documents.vue")
          },
          {
            path: "connections",
            name: "profile-connections",
            component: () => import("@/views/pages/profile/Connections.vue")
          },
          {
            path: "activity",
            name: "profile-activity",
            component: () => import("@/views/pages/profile/Activity.vue")
          }
        ]
      },
      {
        path: "/crafted/pages/wizards/horizontal",
        name: "horizontal-wizard",
        component: () => import("@/views/pages/wizards/Horizontal.vue")
      },
      {
        path: "/crafted/pages/wizards/vertical",
        name: "vertical-wizard",
        component: () => import("@/views/pages/wizards/Vertical.vue")
      },
      {
        path: "/crafted/account",
        name: "account",
        component: () => import("@/views/account/Account.vue"),
        children: [
          {
            path: "overview",
            name: "account-overview",
            component: () => import("@/views/account/Overview.vue")
          },
          {
            path: "settings",
            name: "account-settings",
            component: () => import("@/views/account/Settings.vue")
          }
        ]
      },
      {
        path: "/apps/chat/private-chat",
        name: "apps-private-chat",
        component: () => import("@/views/chat/Chat.vue")
      },
      {
        path: "/apps/chat/group-chat",
        name: "apps-group-chat",
        component: () => import("@/views/chat/Chat.vue")
      },
      {
        path: "/apps/chat/drawer-chat",
        name: "apps-drawer-chat",
        component: () => import("@/views/chat/DrawerChat.vue")
      },
      {
        path: "/crafted/modals/general/invite-friends",
        name: "modals-general-invite-friends",
        component: () => import("@/views/modals/general/InviteFriends.vue")
      },
      {
        path: "/crafted/modals/general/view-user",
        name: "modals-general-view-user",
        component: () => import("@/views/modals/general/ViewUsers.vue")
      },
      {
        path: "/crafted/modals/general/upgrade-plan",
        name: "modals-general-upgrade-plan",
        component: () => import("@/views/modals/general/UpgradePlan.vue")
      },
      {
        path: "/crafted/modals/general/share-and-earn",
        name: "modals-general-share-and-earn",
        component: () => import("@/views/modals/general/ShareAndEarn.vue")
      },
      {
        path: "/crafted/modals/forms/new-target",
        name: "modals-forms-new-target",
        component: () => import("@/views/modals/forms/NewTarget.vue")
      },
      {
        path: "/crafted/modals/forms/new-card",
        name: "modals-forms-new-card",
        component: () => import("@/views/modals/forms/NewCard.vue")
      },
      {
        path: "/crafted/modals/forms/new-address",
        name: "modals-forms-new-address",
        component: () => import("@/views/modals/forms/NewAddress.vue")
      },
      {
        path: "/crafted/modals/forms/create-api-key",
        name: "modals-forms-create-api-key",
        component: () => import("@/views/modals/forms/CreateApiKey.vue")
      },
      {
        path: "/crafted/modals/wizards/two-factor-auth",
        name: "modals-wizards-two-factor-auth",
        component: () => import("@/views/modals/wizards/TwoFactorAuth.vue")
      },
      {
        path: "/crafted/modals/wizards/create-app",
        name: "modals-wizards-create-app",
        component: () => import("@/views/modals/wizards/CreateApp.vue")
      },
      {
        path: "/crafted/modals/wizards/create-account",
        name: "modals-wizards-create-account",
        component: () => import("@/views/modals/wizards/CreateAccount.vue")
      },
      {
        path: "/crafted/widgets/lists",
        name: "widgets-list",
        component: () => import("@/views/widgets/Lists.vue")
      },
      {
        path: "/crafted/widgets/statistics",
        name: "widgets-statistics",
        component: () => import("@/views/widgets/Statistics.vue")
      },
      {
        path: "/crafted/widgets/charts",
        name: "widgets-charts",
        component: () => import("@/views/widgets/Charts.vue")
      },
      {
        path: "/crafted/widgets/mixed",
        name: "widgets-mixed",
        component: () => import("@/views/widgets/Mixed.vue")
      },
      {
        path: "/crafted/widgets/tables",
        name: "widgets-tables",
        component: () => import("@/views/widgets/Tables.vue")
      },
      {
        path: "/crafted/widgets/feeds",
        name: "widgets-feeds",
        component: () => import("@/views/widgets/Feeds.vue")
      }
    ]
  },
  {
    path: "/sign-in",
    name: "sign-in",
    //redirect:  { path: "/dashboard2" }, //tmp
    component: () => import("@/views/auth/SignIn.vue")
  },
  {
    path: "/sign-up",
    name: "sign-up",
    component: () => import("@/views/auth/SignUp.vue")
  },
  {
    path: "/password-reset",
    name: "password-reset",
    component: () => import("@/views/auth/PasswordReset.vue")
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/error/Error404.vue")
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/error/Error500.vue")
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404"
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  //setTimeout(() => {
    store.dispatch(Actions.VERIFY_AUTH);
  //}, 3000);

  const tmpToken = window.localStorage.getItem("id_token");

  /*if(!window.localStorage.getItem("signed_in")) {
    next('sign-in');
  } else {
    next();
  }*/
  next();

  /*//console.log(tmpToken);
  if(to.path !== '/sign-in') {
    if(!tmpToken) {
      //console.log('There is no token, redirect to login. (' + to.path + ')');
      next('sign-in');
    } else {
      //console.log('There is a token, resume. (' + to.path + ')');
      next();
    }
  } else {
    if(!tmpToken) {
      //console.log('There is no token, continue to login. (' + to.path + ')');
      next();
      /////check if userlist exists in localStorage
      if (!window.localStorage.getItem("usersList")) {
        //update userlist if doesn't exist
        ApiService.get("https://cmms.onecorp.cloud/api/v2/auth/users")
            .then(data => {
              window.localStorage.setItem("usersList", JSON.stringify(data.data));
              console.log("user list set to localstorage");
              next();
            })
            .catch(response => {
              console.log(response);
              next('sign-in');
            });
      } else {
        //userlist exists, continue to signin
        next();
      }//
    } else {
      //console.log('There is a token, go to dashboard. (' + to.path + ')');
      next('dashboard');
    }
  }*/
  // next(); - This is in the wrong place
  // if (!JwtService.getToken() && router.currentRoute.path !== "/sign-in") {
  //    router.push({ name: "sign-in" });
  // }

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
