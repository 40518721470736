import LayoutConfigTypes from "@/core/config/LayoutConfigTypes";

const config: LayoutConfigTypes = {
  themeName: "Metronic",
  themeVersion: "8.0.21",
  demo: "demo1",
  main: {
    /*2DO: remove theme references*/
    type: "default",
    primaryColor: "#1b9931",
    logo: {
      dark: "media/logos/spektrgroup.png",
      light: "media/logos/spektrgroup.png"//"media/logos/predsol-logo-dark.svg"
    }
  },
  loader: {
    logo: "media/logos/spektrgroup.png",//"media/logos/predsol-logo.svg",
    display: true,
    type: "spinner-logo"
  },
  scrollTop: {
    display: true
  },
  header: {
    display: true,
    menuIcon: "font",
    width: "fluid",
    fixed: {
      desktop: true,
      tabletAndMobile: true
    }
  },
  toolbar: {
    display: true,
    width: "fluid",
    fixed: {
      desktop: true,
      tabletAndMobile: true
    }
  },
  aside: {
    display: true,
    theme: "light",
    fixed: true,
    menuIcon: "svg",
    minimized: false,
    minimize: true,
    hoverable: true
  },
  content: {
    width: "fluid"
  },
  footer: {
    width: "fluid"
  }
};

export default config;
