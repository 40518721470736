<template>
  <router-view />
</template>

<style lang="scss">
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "~apexcharts/dist/apexcharts.css";
@import "~quill/dist/quill.snow.css";
@import "~animate.css";
@import "~sweetalert2/dist/sweetalert2.css";
@import "~nouislider/distribute/nouislider.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~socicon/css/socicon.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "~@yaireo/tagify/src/tagify.scss";
@import "~dropzone/dist/dropzone.css";
@import "~@vueform/multiselect/themes/default.css";
@import "~prism-themes/themes/prism-shades-of-purple.css";
@import "~element-plus/lib/theme-chalk/index.css";
@import "~@syncfusion/ej2/bootstrap5.css";

// Main demo style scss
@import "assets/sass/plugins";
@import "assets/sass/style";

// Dark mode demo style scss
//@import "assets/sass/plugins.dark";
//@import "assets/sass/style.dark";
</style>

<script lang="ts">
import { defineComponent, onMounted, onBeforeMount } from "vue";
import { useRoute, useRouter } from "vue-router";
import JwtService from "@/core/services/JwtService";
import ApiService from "@/core/services/ApiService";
//import { useStore } from "vuex";
//import { Mutations } from "@/store/enums/StoreEnums";
//import useEmitter from '@/core/helpers/emitter'
import "jquery";

export default defineComponent({
  name: "app",
  setup: function () {
    //const store = useStore();
    const route = useRoute();
    const router = useRouter();
    //const emitter = useEmitter();

    // JwtService.saveToken(
    //     "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJQcmVkc29sRUFNIiwiaWF0IjoxNjI4NDA5NDIyLCJuYmYiOjE2Mjg0MDk0MjIsImV4cCI6MTYyODQ5NTgyMiwidWlkIjoyOTUwLCJ1bm0iOiJZdXNpZiBWZXppcm92In0.uDz0wKK_wnAXccmZMo6E4d_sa6m8MzlB3PNQ74mEdes"
    // );

    //if (JwtService.getToken()) {
    //ApiService.setHeader();
    //console.log("before userlist");
    //} else {
    //  console.log("no token");
    //}
    //check if userlist exists in localStorage
    /*if (!window.localStorage.getItem("usersList")) {
      //update userlist if doesn't exist
      ApiService.get("https://cmms.onecorp.cloud/api/v2/auth/users")
          .then(data => {
            window.localStorage.setItem("usersList", JSON.stringify(data.data));
            console.log("user list set to localstorage");
          })
          .catch(response => {
            console.log(response);
          });
    }*/
    onMounted(() => {
      //console.log("before userlist22");
      //this is to override the layout config using saved data from localStorage
      //remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
      //store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
      //2do: use here for app init logic
    });

    onBeforeMount(() => {
      if(!window.localStorage.getItem("signed_in")) {
        return { path: "/sign-in" };
      }
    });
  }
});
</script>
